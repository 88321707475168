<template>
    <div>
      <h3 v-if="category">Dishes in {{ category.name }}</h3>
      <ul v-if="category">
        <li v-for="dish in filteredDishes" :key="dish.id">
          {{ dish.name }} - ${{ dish.price }} 
          <span v-if="dish.vegan">[Vegan]</span>
          <span v-if="dish.glutenFree">[Gluten Free]</span>
          <span v-if="dish.preferFish">[Fish]</span>
          <span v-if="dish.preferMeat">[Meat]</span>
          <span v-if="dish.preferPasta">[Pasta]</span>
          <span v-if="dish.diet">[Diet]</span>
        </li>
      </ul>
      <p v-else>Please select a category to view dishes.</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      category: Object,
      rules: Array, 
      userPreferences: Object,
    },
    data() {
      return {
        dishes: [
            { id: 1, name: 'Cretan Salad', categoryId: 1, price: 10, vegan: true, glutenFree: true, preferFish: false, preferMeat: false, preferPasta: false, diet: true },
            { id: 2, name: 'Greek Salad', categoryId: 1, price: 12, vegan: true, glutenFree: true, preferFish: false, preferMeat: false, preferPasta: false, diet: true },
            { id: 3, name: 'Italian Salad', categoryId: 1, price: 11, vegan: true, glutenFree: true, preferFish: false, preferMeat: false, preferPasta: false, diet: true },
            { id: 4, name: 'Hummus', categoryId: 2, price: 8, vegan: true, glutenFree: true, preferFish: false, preferMeat: false, preferPasta: false, diet: true },
            { id: 5, name: 'Tzatziki', categoryId: 2, price: 8, vegan: true, glutenFree: true, preferFish: false, preferMeat: false, preferPasta: false, diet: true },
            { id: 6, name: 'Meat Balls', categoryId: 2, price: 9, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: true },
            { id: 7, name: 'Moussaka', categoryId: 3, price: 15, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: false },
            { id: 8, name: 'Pastitsio', categoryId: 3, price: 14, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: false },
            { id: 9, name: 'Souvlaki', categoryId: 3, price: 13, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: false },
            { id: 10, name: 'Chicken Sandwitch', categoryId: 4, price: 10, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: false },
            { id: 11, name: 'Club Sandwich', categoryId: 4, price: 11, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: false, diet: false },
            { id: 12, name: 'Salmon Sandwitch', categoryId: 4, price: 12, vegan: false, glutenFree: false, preferFish: true, preferMeat: false, preferPasta: false, diet: false },
            { id: 13, name: 'Spaghetti', categoryId: 5, price: 12, vegan: false, glutenFree: false, preferFish: false, preferMeat: false, preferPasta: true, diet: false },
            { id: 14, name: 'Carbonara', categoryId: 5, price: 13, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: true, diet: false },
            { id: 15, name: 'Lasagna', categoryId: 5, price: 14, vegan: false, glutenFree: false, preferFish: false, preferMeat: true, preferPasta: true, diet: false },
            { id: 16, name: 'Baklava', categoryId: 6, price: 6, vegan: false, glutenFree: false, preferFish: false, preferMeat: false, preferPasta: false, diet: false },
            { id: 17, name: 'Cheesecake', categoryId: 6, price: 7, vegan: false, glutenFree: false, preferFish: false, preferMeat: false, preferPasta: false, diet: false },
            { id: 18, name: 'Tiramisu', categoryId: 6, price: 7, vegan: false, glutenFree: false, preferFish: false, preferMeat: false, preferPasta: false, diet: false },
        ],
      };
    },
    computed: {
      filteredDishes() {
        if (!this.category) return [];
        let result = this.dishes
        .filter(dish => dish.categoryId === this.category.id)
        .map(dish => ({ ...dish, weight: 0 })); // Initialize weight for sorting

    
        // check if there are any rules defined
        if (this.$parent.rules.length === 0) return result;
        // Apply each rule
        for (let rule of this.rules) {
            if (rule.action === 'hide') {
                // hide the dish if it does not match the UserPreference
                result = result.filter(dish => {
    
                if (rule.category === 'any' || dish.categoryId.toString() === rule.category.toString()) { 
                    //console.log('category matched', dish.categoryId.toString(), rule.category.toString());
                    if (rule.product === 'any' || dish.id.toString() === rule.product.toString()) {
                        //console.log('product matched', dish.id.toString(), rule.product.toString());
                        if (rule.userPreference !== 'any' ) {
                            //console.log('userPreference matched', rule.userPreference, dish[rule.userPreference]);
                            if(!this.userPreferences[rule.userPreference] && dish[rule.userPreference]) {
                                return false;
                            } else if (this.userPreferences[rule.userPreference] && !dish[rule.userPreference]) {
                                return false;
                            }
                            
                        } else if (rule.userPreference === 'any') {
                        // Iterate over all userPreferences
                            let keepDish = true
                            for (let preference in this.userPreferences) {
                                if(this.userPreferences[preference] && !dish[preference]) {
                                    keepDish = false;
                                    break; // exit the loop as soon as a mismatch is found
                                }
                            }
                            return keepDish;           
                        }
                    }   
                }
                

                return true; // Hide the dish if all conditions are met
                });
            }
            else if (rule.action === 'promote') {
                // promote logic is similar to the hide logic but each time there is a match we add 1 to the dish weight. 
                // Dishes with higher weight will be shown first
                result.forEach(dish => {
                    if ((rule.category === 'any' || dish.categoryId.toString() === rule.category.toString()) && 
                        (rule.product === 'any' || dish.id.toString() === rule.product.toString())) {
                    if (rule.userPreference !== 'any') {
                        if(this.userPreferences[rule.userPreference] && dish[rule.userPreference]) {
                        dish.weight += 1; // Promote the dish
                        }
                    } else {
                        // If rule applies to any userPreference, check all preferences
                        let matchesPreference = Object.keys(this.userPreferences).some(preference => {
                        return this.userPreferences[preference] && dish[preference];
                        });
                        if (matchesPreference) {
                        dish.weight += 1; // Promote the dish
                        }
                    }
                    }
                });
            }
        }
        // Sort dishes based on their weight in descending order
        return result.sort((a, b) => b.weight - a.weight);

      },
    },
  };
  </script>
  