<template>
    <div>
      <h2>Select a Category</h2>
      <select @change="selectCategory($event.target.value)">
        <option value="">Please select</option>
        <option v-for="category in categories" :value="category.id" :key="category.id">
          {{ category.name }}
        </option>
      </select>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        categories: [
          { id: 1, name: 'Salads' },
          { id: 2, name: 'Appetizers' },
          { id: 3, name: 'Main Dishes' },
          { id: 4, name: 'Sandwiches' },
          { id: 5, name: 'Pasta' },
          { id: 6, name: 'Desserts' },
        ],
      };
    },
    methods: {
      selectCategory(categoryId) {
        const category = this.categories.find(category => category.id.toString() === categoryId);
        this.$emit('category-selected', category);
      },
    },
  };
  </script>
  