<template>
    <div>
        <p v-for="(rule, index) in rules" :key="index">
            {{ formatRule(rule) }}
        </p>
    </div>
</template>
<script>
export default {
  props: {
    rules: Array, 
  },
  methods: {
    formatRule(rule) {
      return `userPreference("${rule.userPreference}"):Category("${rule.category}").Product("${rule.product}")->Action("${rule.action}")`;
    }
  }
};
</script>