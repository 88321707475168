<template>
    <div>
      <h2>Add DSL Rule</h2>
      <form @submit.prevent="addRule">
        <div>
          <label for="userPreference">User Preference (X):</label>
          <select id="userPreference" v-model="newRule.userPreference">
            <option value="vegan">Vegan</option>
            <option value="glutenFree">Gluten Free</option>
            <option value="preferFish">Prefer Fish</option>
            <option value="preferMeat">Prefer Meat</option>
            <option value="preferPasta">Prefer Pasta</option>
            <option value="diet">Diet</option>
            <option value="any">Any</option>
          </select>
        </div>
        <div>
          <label for="category">Category (Y):</label>
          <select id="category" v-model="newRule.category">
            <option value="any">Any</option>
            <!-- Dynamically generate category options -->
            <option v-for="category in categories" :value="category.id" :key="category.id">
              {{ category.name }} ({{ category.id }})
            </option>
          </select>
        </div>
        <div>
          <label for="product">Product (Z):</label>
          <select id="product" v-model="newRule.product">
            <option value="any">Any</option>
            <!-- Dynamically generate product options -->
            <option v-for="dish in dishes" :value="dish.id" :key="dish.id">
              {{ dish.name }} ({{ dish.id }})
            </option>
          </select>
        </div>
        <div>
          <label for="action">Action (A):</label>
          <select id="action" v-model="newRule.action">
            <option value="hide">Hide Not Matching</option>
            <option value="promote">Promote Matching</option>
          </select>
        </div>
        <button type="submit">Add Rule</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // Example categories and dishes, should be dynamically loaded or passed as props
        categories: [
          { id: 1, name: 'Salads' },
          { id: 2, name: 'Appetizers' },
          { id: 3, name: 'Main Dishes' },
          { id: 4, name: 'Sandwiches' },
          { id: 5, name: 'Pasta' },
          { id: 6, name: 'Desserts' },
        ],
        dishes: [
            { id: 1, name: 'Cretan Salad', categoryId: 1},
            { id: 2, name: 'Greek Salad', categoryId: 1},
            { id: 3, name: 'Italian Salad', categoryId: 1},
            { id: 4, name: 'Hummus', categoryId: 2},
            { id: 5, name: 'Tzatziki', categoryId: 2},
            { id: 6, name: 'Meat Balls', categoryId: 2},
            { id: 7, name: 'Moussaka', categoryId: 3},
            { id: 8, name: 'Pastitsio', categoryId: 3},
            { id: 9, name: 'Souvlaki', categoryId: 3},
            { id: 10, name: 'Chicken Sandwitch', categoryId: 4},
            { id: 11, name: 'Club Sandwich', categoryId: 4},
            { id: 12, name: 'Salmon Sandwitch', categoryId: 4},
            { id: 13, name: 'Spaghetti', categoryId: 5},
            { id: 14, name: 'Carbonara', categoryId: 5},
            { id: 15, name: 'Lasagna', categoryId: 5},
            { id: 16, name: 'Baklava', categoryId: 6},
            { id: 17, name: 'Cheesecake', categoryId: 6 },
            { id: 18, name: 'Tiramisu', categoryId: 6 }
        ],
        newRule: {
          userPreference: 'any',
          category: 'any',
          product: 'any',
          action: 'hide',
        },
      };
    },
    methods: {
      addRule() {
        this.$emit('rule-added', this.newRule);
        
        // Reset form after submission for simplicity
        this.newRule = { userPreference: 'any', category: 'any', product: 'any', action: 'hide' };
      },
    },
  };
  </script>
  