<template>
  <h2>DSL Rule Tester</h2>
  <div id="app">
    <div class="left-column">
      <CategoryList @category-selected="selectCategory" />
      <DishList :rules="rules" :category="selectedCategory" :userPreferences="userPreferences"/>
      <UserPreferencesWidget @user-preferences-changed="handleUserPreferencesChange" />
    </div>
    <div class="main-content">
      <DSLRulesComposer @rule-added="handleRuleAdded" />
      <DSLRulesList :rules="rules" />
    </div>
  </div>
  
</template>


<script>
import CategoryList from './components/CategoryList.vue';
import DishList from './components/DishList.vue';
import UserPreferencesWidget from './components/UserPreferencesWidget.vue';
import DSLRulesComposer from './components/DSLRulesComposer.vue';
import DSLRulesList from './components/DSLRulesList.vue';

export default {
  name: 'App',
  components: {
    CategoryList,
    DishList,
    UserPreferencesWidget,
    DSLRulesComposer,
    DSLRulesList,
  },
  data() {
    return {
      rules: [],
      userPreferences:  { "vegan": false, "glutenFree": false, "preferFish": false, "preferMeat": false, "preferPasta": false, "diet": false },
      selectedCategory: null,
    };
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    handleRuleAdded(newRule) {
      console.log('Adding rule:', newRule);
      this.rules.push(newRule);
    },
    handleUserPreferencesChange(preferences) {
      console.log('User preferences changed:', preferences);
      this.userPreferences = preferences;
    },
  },
};
</script>
<style scoped>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.left-column {
  width: 30%;
  padding: 10px;
  margin: 10px;
  background-color: beige;
  overflow: auto; /* Add this if you want scrollbar when content overflows */
}

.main-content {
  flex-grow: 1; /* This will take up the remaining space */
  height: 100%;
}
</style>