<template>
    <div>
      <h2>User Preferences</h2>
      <form>
        <div>
          <input type="checkbox" id="vegan" v-model="preferences.vegan" @change="onchangePreferences()"/>
          <label for="vegan">Vegan</label>
        </div>
        <div>
          <input type="checkbox" id="glutenFree" v-model="preferences.glutenFree" @change="onchangePreferences()" />
          <label for="glutenFree">Gluten Free</label>
        </div>
        <div>
          <input type="checkbox" id="preferFish" v-model="preferences.preferFish" @change="onchangePreferences()" />
          <label for="preferFish">Prefer Fish</label>
        </div>
        <div>
          <input type="checkbox" id="preferMeat" v-model="preferences.preferMeat" @change="onchangePreferences()" />
          <label for="preferMeat">Prefer Meat</label>
        </div>
        <div>
          <input type="checkbox" id="preferPasta" v-model="preferences.preferPasta" @change="onchangePreferences()" />
          <label for="preferPasta">Prefer Pasta</label>
        </div>
        <div>
          <input type="checkbox" id="diet" v-model="preferences.diet" @change="onchangePreferences()"/>
          <label for="diet">Diet</label>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        preferences: {
          vegan: false,
          glutenFree: false,
          preferFish: false,
          preferMeat: false,
          preferPasta: false,
          diet: false,
        },
      };
    },
    methods: {
      onchangePreferences() {
        this.$emit('user-preferences-changed', this.preferences);
      },
    },
  };
  </script>
  